import { useRequest } from "../helpers/useRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useMessage } from "../helpers/useMessages";

const url = api.offices;

export const useGetAllOffices = () => {
  const { get } = useRequest();
  return useQuery<Office[]>(queryEnums.offices, () => get(`${url}`));
};

export const useAddOffice = () => {
  const { post } = useRequest();
  const queryClient = useQueryClient();
  const { sendAdded } = useMessage();

  return useMutation((office: Office) => post(`${url}`, office), {
    onSuccess: (_result, office) => {
      sendAdded(office.name);
      queryClient.invalidateQueries(queryEnums.offices);
    },
  });
};

export const useDeleteOffice = () => {
  const { _delete } = useRequest();
  const queryClient = useQueryClient();
  const { sendRemoved } = useMessage();
  return useMutation((id?: string) => _delete(`${url}/${id}`), {
    onSuccess: () => {
      sendRemoved();
      queryClient.invalidateQueries(queryEnums.offices);
    },
  });
};

export const useGetOfficeById = (id?: string) => {
  const { get } = useRequest();
  return useQuery<Office>([queryEnums.offices_get, id], () => get(`${url}/${id}`), {
    enabled: !!id,
  });
};

export const useGetOfficesByUserId = (id?: string) => {
  const { get } = useRequest();
  return useQuery<Office[]>([queryEnums.offices_get, id], () => get(`${url}/userOffices/${id}`), {
    enabled: !!id,
  });
};

export const useUpdateOffice = () => {
  const { put } = useRequest();
  const queryClient = useQueryClient();
  const { sendUpdated } = useMessage();
  return useMutation((office: Office) => put(`${url}`, office), {
    onSuccess: (_result, office) => {
      sendUpdated(office.name);
      queryClient.invalidateQueries(queryEnums.offices);
      queryClient.invalidateQueries(queryEnums.offices_get);
    },
  });
};
