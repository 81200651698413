import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { header, userSideBarHeader } from "../../config/header";
import { routes } from "../../config/routes";
import { useAuth } from "../../helpers/useAuth";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../recoil/auth/atom";
import Roles from "../../constants/userRoles";
import { superAdmins } from "../../config/roles";

export const SideBar = () => {
  const { isAuthenticated } = useAuth();
  const auth = useRecoilValue(authAtom);
  const filteredHeader = superAdmins.includes(auth?.email.toLowerCase())
    ? header
    : header.filter((item) => item !== "organisations");

  return (
    <Navbar bg="success" variant="dark" className="h-100 flex-column px-3">
      <Nav className="me-auto flex-column text-white w-100 mt-4">
        {isAuthenticated() &&
        auth.roles.some((role: any) => role === Roles.Admin)
          ? filteredHeader.map((header: string) => (
              <NavLink
                className="nav-link nav-content text-capitalize p-2 h6"
                key={header}
                to={routes[header].path}
              >
                {header}
              </NavLink>
            ))
          : isAuthenticated() &&
            userSideBarHeader.map((userSideBarHeader: string) => (
              <NavLink
                className="nav-link nav-content text-capitalize p-2 h6"
                key={userSideBarHeader}
                to={routes[userSideBarHeader].path}
              >
                {userSideBarHeader}
              </NavLink>
            ))}
      </Nav>
    </Navbar>
  );
};
