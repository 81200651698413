import { useRecoilValue } from "recoil";
import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { ProjectsContainer } from "./ProjectsContainer/ProjectsContainer";
import { useGetAllProjectsByUser } from "../../services/projectService";
import { authAtom } from "../../recoil/auth/atom";

export const ProjectPage = () => {
  const auth = useRecoilValue(authAtom);
  const { data } = useGetAllProjectsByUser(auth.id);

  return (
    <PageContent title={strings.projectPage}>
      <ProjectsContainer projects={data || []} />
    </PageContent>
  );
};
