import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../config/string";
import { useGetFolderById, useUpdateFolder } from "../../../services/folderService";
import { useGetAllOffices } from "../../../services/officeService";
import { EditEntityModal } from "../../../components/EditEntityModal/EditEntityModal";
import { FolderForm } from "../FolderForm/FolderForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { FolderFormValidationSchema } from "../../../config/FormValidations";

type Props = {
  show: boolean;
  handleClose: () => void;
  folderId?: string;
  officeId?: string;
};

export const EditFolderModal = ({ show, handleClose, folderId, officeId }: Props) => {
  const { data } = useGetFolderById(folderId);
  const { mutate: updateFolder } = useUpdateFolder();
  const { data: offices } = useGetAllOffices();

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FolderFormValidationSchema),
  });

  return (
    <div>
      <EditEntityModal
        show={show}
        handleClose={handleClose}
        message={strings.editFolderModalMessage}
        title={strings.editFolderModalTitle}
        onUpdate={updateFolder}
        data={data}
        handleSubmit={handleSubmit}
        reset={reset}
        entityForm={
          <FolderForm
            folder={data || ({} as Folder)}
            handleSubmit={handleSubmit}
            register={register}
            officeId={officeId}
            offices={offices || []}
            errors={errors}
          />
        }
      />
    </div>
  );
};
