import { atom } from "recoil";

const authAtom = atom({
  key: "auth",
  default: JSON.parse(localStorage.getItem("user")),
});

const messageAtom = atom({
  key: "toast",
  default: "",
});

export { authAtom, messageAtom };
