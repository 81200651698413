import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const CustomLoader = () => {
  return (
    <div className="d-flex justify-content-center">
      <Loader type="ThreeDots" color="#ffffff" height={100} width={100} />
    </div>
  );
};
