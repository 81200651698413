import { useRequest } from "../helpers/useRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useMessage } from "../helpers/useMessages";

const url = api.folders;

export const useGetAllFolders = () => {
  const { get } = useRequest();
  return useQuery<Folder[]>(queryEnums.folders, () => get(`${url}`, null));
};

export const useAddFolder = () => {
  const { post } = useRequest();
  const queryClient = useQueryClient();
  const { sendAdded } = useMessage();

  return useMutation((folder: Folder) => post(`${url}`, folder), {
    onSuccess: (_result, folder) => {
      sendAdded(folder.name);
      queryClient.invalidateQueries(queryEnums.folders);
      queryClient.invalidateQueries(queryEnums.foldersByOfficeId_get);
    },
  });
};

export const useGetFolderById = (id?: string) => {
  const { get } = useRequest();
  return useQuery([queryEnums.folders_get, id], () => get(`${url}/${id}`, null), {
    enabled: !!id,
  });
};

export const useUpdateFolder = () => {
  const { put } = useRequest();
  const queryClient = useQueryClient();
  const { sendUpdated } = useMessage();
  return useMutation((folder: Folder) => put(`${url}/${folder.id}`, folder), {
    onSuccess: (_result, folder) => {
      sendUpdated(folder.name);
      queryClient.invalidateQueries(queryEnums.folders);
      queryClient.invalidateQueries(queryEnums.folders_get);
      queryClient.invalidateQueries(queryEnums.foldersByOfficeId_get);
    },
  });
};

export const useDeleteFolder = () => {
  const { _delete } = useRequest();
  const queryClient = useQueryClient();
  const { sendRemoved } = useMessage();
  return useMutation((id?: string) => _delete(`${url}/${id}`, null), {
    onSuccess: () => {
      sendRemoved();
      queryClient.invalidateQueries(queryEnums.folders);
      queryClient.invalidateQueries(queryEnums.foldersByOfficeId_get);
    },
  });
};

export const useGetFolderByOfficeId = (id?: string) => {
  const { get } = useRequest();
  return useQuery<Folder[]>([queryEnums.foldersByOfficeId_get, id], () => get(`${url}/office/${id}`));
};

export const useGetFolderByOrganisationId = (id?: string) => {
  const { get } = useRequest();
  return useQuery<Folder[]>([queryEnums.foldersByOrganisationId_get, id], () => get(`${url}/organisation/${id}`));
};
