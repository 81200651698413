import { useRecoilValue } from "recoil";
import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { OrganisationContainer } from "./OrganisationContainer/OrganisationContainer";
import { authAtom } from "../../recoil/auth/atom";
import { superAdmins } from "../../config/roles";

export const OrganisationPage = () => {
  const auth = useRecoilValue(authAtom);

  return (
    <>
      {superAdmins.includes(auth.email.toLowerCase()) ? (
        <PageContent title={strings.organisationPage}>
          <OrganisationContainer />
        </PageContent>
      ) : null}
    </>
  );
};
