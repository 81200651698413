import { Row } from "react-bootstrap";

type Props = {
  title: string;
  children?: React.ReactNode;
};

export const PageContent = ({ title, children }: Props) => {
  return (
    <Row className={`p-3`}>
      <h2>{title}</h2>
      {children}
    </Row>
  );
};
