import { useState } from "react";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGetAllOffices } from "../../../services/officeService";
import { OfficeItem } from "../OfficeItem/OfficeItem";
import { OfficeCrudModals } from "../OfficeCrudModals/OfficeCrudModals";
import { AddEntityButton } from "../../../components/AddEntityButton/AddEntityButton";
import { strings } from "../../../config/string";

export const OfficesContainer = () => {
  const { data } = useGetAllOffices();
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>();
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const history = useHistory();

  return (
    <div>
      <AddEntityButton onClick={() => setShowAdd(true)} text={strings.addNewOffice} />
      <OfficeCrudModals
        showAdd={showAdd}
        officeId={selectedOfficeId}
        showDelete={showDelete}
        showEdit={showEdit}
        onAddClose={() => setShowAdd(false)}
        onEditClose={() => setShowEdit(false)}
        onDeleteClose={() => setShowDelete(false)}
      />
      <Row xs={1} sm={2} lg={3} xl={4} className="mt-3">
        {data &&
          data.map((office, index) => (
            <OfficeItem
              key={`${office.id}_${index}`}
              office={office}
              onClickEdit={() => {
                setSelectedOfficeId(office.id);
                setShowEdit(true);
              }}
              onClickDelete={() => {
                setSelectedOfficeId(office.id);
                setShowDelete(true);
              }}
              onClickFolders={() => {
                history.push(`/offices/${office.id}`);
              }}
            />
          ))}
      </Row>
    </div>
  );
};
