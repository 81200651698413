import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { strings } from "../../../config/string";
import { useDeleteOrganisation } from "../../../services/organisationService";

type Props = {
  show: boolean;
  handleClose: () => void;
  organisationId?: string;
};

export const DeleteOrganisationModal = ({
  show,
  handleClose,
  organisationId,
}: Props) => {
  const { mutate: deleteorganisation } = useDeleteOrganisation();

  return (
    <ConfirmationModal
      title={strings.deleteOrganisationModalTitle}
      message={strings.deleteOrganisationModalMessage}
      show={show}
      handleConfirmation={() => {
        deleteorganisation(organisationId);
        handleClose();
      }}
      handleClose={handleClose}
    />
  );
};
