import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

type Props = {
  error: any;
  resetErrorBoundary: () => void;
};

export const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  const location = useLocation();
  const [hasError, setHasError] = useState(null);

  useEffect(() => {
    if (error && !hasError) {
      setHasError(error);
    } else if (error && hasError) {
      setHasError(null);
      resetErrorBoundary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Container fluid className="p-4">
      <div>
        <div>Something went wrong.</div>
        <div className="mt-2 font-weight-light small">
          <i>Error: {error.message}</i>
        </div>
        <Button onClick={resetErrorBoundary} className="mt-4">
          Try again
        </Button>
      </div>
    </Container>
  );
};
