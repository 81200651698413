import { Card, Button } from "react-bootstrap";
import { DeleteButton } from "../../../components/DeleteButton/DeleteButton";
import { DisplayItem } from "../../../components/DisplayItem/DisplayItem";
import { EditButton } from "../../../components/EditButton/EditButton";
import { strings } from "../../../config/string";

type Props = {
  office: Office;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickFolders: () => void;
};

export const OfficeItem = ({ office, onClickEdit, onClickDelete, onClickFolders }: Props) => {
  return (
    <DisplayItem>
      <Card.Body>
        <Card.Title>{office.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-white opacity-75">{office.description}</Card.Subtitle>
      </Card.Body>
      <Card.Footer className="pt-0">
        <div className="d-flex justify-content-between">
          <EditButton onClick={onClickEdit} />
          <DeleteButton onClick={onClickDelete} />
          <Button
            className="ms-auto float-right btn bg-primary border-dark text-white p-2 opacity-100 badge mt-2"
            variant="warning"
            onClick={onClickFolders}
          >
            {strings.showFolders}
          </Button>
        </div>
      </Card.Footer>
    </DisplayItem>
  );
};
