import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { UsersContainer } from "./UsersContainer/UsersContainer";

export const UserPage = () => {
  return (
    <PageContent title={strings.userPage}>
      <UsersContainer />
    </PageContent>
  );
};
