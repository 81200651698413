import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { FoldersContainer } from "./FoldersContainer/FoldersContainer";
import { useGetAllFolders } from "../../services/folderService";

export const FolderPage = () => {
  const { data } = useGetAllFolders();

  return (
    <PageContent title={strings.folderPage}>
      <FoldersContainer folders={data || []} />
    </PageContent>
  );
};
