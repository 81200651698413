import { useState } from "react";
import { PageContent } from "../../components/PageContent/PageContent";
import { useAuth } from "../../helpers/useAuth";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../recoil/auth/atom";
import { LoginForm } from "./LoginForm/LoginForm";
import { strings } from "../../config/string";
import { LoginError } from "./LoginError/LoginError";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";

export const LoginPage = () => {
  const { login } = useAuth();
  const auth = useRecoilValue(authAtom);
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth) {
      history.push("/dashboard");
    }
  }, [auth, history]);

  const _login = (user: LoginUser) => {
    setLoading(true);
    login(user.email, user.password).then((result: string) => {
      setLoggedIn(result);
      setLoading(false);
    });
  };

  return (
    <PageContent title={strings.loginPage}>
      <LoginForm onSubmit={_login} />
      <LoginError message={loggedIn} />
      {loading && <CustomLoader />}
    </PageContent>
  );
};
