import { useRecoilState } from "recoil";
import { authAtom } from "../recoil/auth/atom";
import ky from "ky";

export const useRequest = () => {
  const [auth, setAuth] = useRecoilState(authAtom);

  const request = (method) => {
    return (url, body, overrideUrl = false, formData = false) => {
      const requestOptions = {
        method,
        headers: authHeader(url),
        hooks: {
          afterResponse: [
            async (request, options, response) => {
              if (response.status === 401) {
                const user = await ky
                  .post(
                    `${process.env.REACT_APP_API_URL}authorization/refresh-token?&id=${auth.id}`
                  )
                  .json();
                if (user.isAuthenticated) {
                  setAuth(user);
                  request.headers.set("Authorization", `Bearer ${user.token}`);
                  return ky(request);
                } else {
                  localStorage.removeItem("user");
                  setAuth(null);
                }
              }
            },
          ],
        },
      };
      if (body) {
        if (!formData) {
          requestOptions.headers["Content-Type"] = "application/json";
          requestOptions.body = JSON.stringify(body);
        } else {
          requestOptions.body = body;
        }
      }

      return _fetch(url, requestOptions, overrideUrl);
    };
  };

  const authHeader = (url) => {
    const token = auth?.token;
    const isLoggedIn = !!token;
    if (isLoggedIn) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {};
    }
  };

  const _fetch = async (url, requestOptions, overrideUrl) => {
    const _url = overrideUrl ? url : process.env.REACT_APP_API_URL + url;
    const data = ky(_url, requestOptions).json();
    return data;
  };

  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    _delete: request("DELETE"),
  };
};
