import { Col, Form, Row } from "react-bootstrap";
import { UseFormRegister } from "react-hook-form";
import { strings } from "../../../config/string";

type Props = {
  createOrganisationDTO: createOrganizationDTO;
  handleSubmit: any;
  register: UseFormRegister<createOrganizationDTO>;
  errors: any;
  create?: boolean;
  setValue: React.Dispatch<React.SetStateAction<Blob | null>>;
};

export const OrganisationForm = ({
  createOrganisationDTO,
  handleSubmit,
  register,
  errors,
  create,
  setValue,
}: Props) => {
  console.log("OrgDOT", createOrganisationDTO);
  return (
    <Form onSubmit={handleSubmit}>
      {false && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            ID
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder={"ID"}
              defaultValue={createOrganisationDTO.organization.id}
              {...register("organization.id", {
                required: true,
                maxLength: 300,
              })}
            />
          </Col>
        </Form.Group>
      )}

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          {strings.formFieldName}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            placeholder={strings.formPlaceholderName}
            defaultValue={createOrganisationDTO.organization.name}
            {...register("organization.name", {
              required: true,
              maxLength: 80,
            })}
          />
          <span className="text-danger error-message">
            {errors.name?.message}
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Org Number (10 digits)
        </Form.Label>
        <Col sm="10">
          <Form.Control
            placeholder={"Organisation Number"}
            defaultValue={createOrganisationDTO.organization.organizationNumber?.toString()}
            {...register("organization.organizationNumber", {
              required: true,
            })}
          />
          <span className="text-danger error-message">
            {errors.name?.message}
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          {strings.formFieldDesc}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            placeholder={strings.formPlaceholderDesc}
            defaultValue={createOrganisationDTO.organization.description}
            {...register("organization.description", {
              required: true,
              maxLength: 300,
            })}
          />
          <span className="text-danger error-message">
            {errors.description?.message}
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Logo
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="file"
            placeholder={"Logo"}
            {...register("organization.logo", { required: false })}
            // defaultValue={createOrganisationDTO.organization.logo}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.files && event.target.files.length > 0) {
                const file = event.target.files[0];
                if (file.name.split(".").pop() === "png") {
                  setValue(
                    new Blob([file], {
                      type: file.type,
                    })
                  );
                } else {
                  alert("Please choose a png file");
                }
              }
            }}
          />
        </Col>
      </Form.Group>

      {create && (
        <>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              User Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder={"User Name"}
                defaultValue={""}
                {...register("defaultUser.userName", {
                  required: true,
                  maxLength: 300,
                })}
              />
              <span className="text-danger error-message">
                {errors.description?.message}
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder={"Email"}
                defaultValue={""}
                {...register("defaultUser.email", {
                  required: true,
                  maxLength: 300,
                })}
              />
              <span className="text-danger error-message">
                {errors.defaultUser?.email?.message}
              </span>
            </Col>
          </Form.Group>
        </>
      )}
      {createOrganisationDTO.organization.logo && (
        <img
          src={createOrganisationDTO.organization.logo}
          width={100}
          height={100}
          style={{ objectFit: "contain" }}
          alt=""
        />
      )}
    </Form>
  );
};
