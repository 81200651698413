import { useForm } from "react-hook-form";
import { strings } from "../../../config/string";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { useAddFolder } from "../../../services/folderService";
import { useGetAllOffices } from "../../../services/officeService";
import { yupResolver } from "@hookform/resolvers/yup";
import { FolderForm } from "../FolderForm/FolderForm";
import { FolderFormValidationSchema } from "../../../config/FormValidations";

type Props = {
  show: boolean;
  handleClose: () => void;
  officeId?: string;
};

export const AddFolderModal = ({ show, handleClose, officeId }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FolderFormValidationSchema),
  });
  const { mutate: addFolder } = useAddFolder();
  const { data: offices } = useGetAllOffices();

  const handleConfirmation = (folder: Folder) => {
    addFolder(folder);
    handleClose();
    reset();
  };

  const folder = {} as Folder;
  const handleModalClose = () => {
    handleClose();
    reset();
  };

  return (
    <div>
      <ConfirmationModal
        show={show}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        message={strings.newFolderModalMessage}
        title={strings.newFolderModalTitle}
      >
        <FolderForm
          offices={offices || []}
          errors={errors}
          folder={folder}
          officeId={officeId}
          register={register}
          handleSubmit={handleSubmit}
        />
      </ConfirmationModal>
    </div>
  );
};
