import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { strings } from "../../config/string";

type Props = {
  onClick: () => void;
};

export const BackButton = ({ onClick }: Props) => {
  return (
    <Button onClick={onClick} className="ms-auto mb-2" variant="warning">
      <FontAwesomeIcon
        // @ts-ignore
        icon={faArrowLeft}
        className="me-2"
      />
      {strings.back}
    </Button>
  );
};
