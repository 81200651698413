import { useState } from "react";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FolderCrudModals } from "../FolderCrudModals/FolderCrudModals";
import { AddEntityButton } from "../../../components/AddEntityButton/AddEntityButton";
import { strings } from "../../../config/string";
import { FolderItem } from "../FolderItem/FolderItem";

type Props = {
  folders: Folder[];
  officeId?: string;
  organisationId?: string;
};

export const FoldersContainer = ({ folders, officeId }: Props) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [folderId, setFolderId] = useState<string>();
  const history = useHistory();

  return (
    <div>
      <AddEntityButton
        onClick={() => setShowAdd(true)}
        text={strings.addNewFolder}
      />
      <FolderCrudModals
        showAdd={showAdd}
        showDelete={showDelete}
        showEdit={showEdit}
        folderId={folderId}
        officeId={officeId}
        onAddClose={() => setShowAdd(false)}
        onDeleteClose={() => setShowDelete(false)}
        onEditClose={() => setShowEdit(false)}
      />

      <Row xs={1} sm={2} lg={3} xl={4} className="mt-3">
        {folders &&
          folders.map((folder, index) => (
            <FolderItem
              key={`${folder.id}_${index}`}
              folder={folder}
              onClickEdit={() => {
                setFolderId(folder.id);
                setShowEdit(true);
              }}
              onClickDelete={() => {
                setFolderId(folder.id);
                setShowDelete(true);
              }}
              onClickProjects={() => {
                history.push(`/folders/${folder.id}`);
              }}
            />
          ))}
      </Row>
    </div>
  );
};
