import { Button } from "react-bootstrap";
import { FoldersContainer } from "../../../pages/FolderPage/FoldersContainer/FoldersContainer";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useGetFolderByOfficeId } from "../../../services/folderService";
import { strings } from "../../../config/string";
import { useGetOfficeById } from "../../../services/officeService";
type Props = {
  officeId?: string;
};

export const OfficeDetailContainer = ({ officeId }: Props) => {
  const history = useHistory();

  const { data: folders } = useGetFolderByOfficeId(officeId);
  const { data: office } = useGetOfficeById(officeId);

  return (
    <div className="mt-3">
      <h4>{office ? office.name : ""}</h4>
      <Button
        onClick={() => {
          history.push("/offices");
        }}
        className="ms-auto mb-2"
        variant="warning"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
        {strings.back}
      </Button>
      <FoldersContainer folders={folders || []} officeId={officeId} />
    </div>
  );
};
