import React from "react";
import { PageContent } from "../../components/PageContent/PageContent";
import { ResetPasswordForm } from "./ResetPasswordForm/ResetPasswordForm";
import { useSendPasswordReset } from "../../services/userService";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../../helpers/useAuth";

export function ResetPasswordPage(): React.ReactElement {
  const { mutateAsync } = useSendPasswordReset();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  let url = new URL(window.location.href);
  let rawToken = url.search.split("token=")[1];
  if (rawToken.includes("&")) {
    rawToken = rawToken.split("&")[0];
  }
  const decodedToken = decodeURIComponent(rawToken);
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  const { login } = useAuth();
  const { push } = useHistory();

  console.log({ token }, { rawToken });

  if (typeof token !== "string" || typeof email !== "string") {
    return <>ERROR IN QUERY PARAMS TOKEN</>;
  }

  return (
    <PageContent title={"Reset your password"}>
      <ResetPasswordForm
        onSubmit={async (e) => {
          debugger;
          mutateAsync({
            password: e.password,
            email,
            token: decodedToken,
          }).then(async () => {
            await login(email, e.password);
            push("/dashboard");
          });
        }}
      />
    </PageContent>
  );
}
