import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { OfficesContainer } from "./OfficesContainer/OfficesContainer";

export const OfficePage = () => {
  return (
    <PageContent title={strings.officePage}>
      <OfficesContainer />
    </PageContent>
  );
};
