import { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Content } from "../Content/Content";
import { Header } from "../Header/Header";
import { MessageToast } from "../MessageToast/MessageToast";
import { SideBar } from "../SideBar/SideBar";

type Props = {
  children?: ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <>
      <div className="">
        <MessageToast />
        <Container className="bg-primary" fluid>
          <Header />
        </Container>
        <Container className="bg-white" fluid>
          <Row>
            <Col md={2} sm={12} className="p-0">
              <SideBar />
            </Col>
            <Col md={10} sm={12} className="p-0">
              <Content>{children}</Content>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
