import { Col, Form, Row } from "react-bootstrap";
import { UseFormRegister } from "react-hook-form";
import { strings } from "../../../config/string";

type Props = {
  folder: Folder;
  handleSubmit: any;
  register: UseFormRegister<Folder>;
  offices: Office[];
  errors: any;
  officeId?: string;
};

export const FolderForm = ({ folder, handleSubmit, register, offices, officeId, errors }: Props) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3" controlId="fieldName">
        <Form.Label column sm="3">
          {strings.formFieldName}
        </Form.Label>
        <Col sm="9">
          <Form.Control
            placeholder={strings.formPlaceholderName}
            defaultValue={folder.name}
            {...register("name", { required: true, maxLength: 80 })}
          />
          <span className="text-danger error-message">{errors.name?.message}</span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="office">
        <Form.Label column sm="3">
          {strings.formFieldOffice}
        </Form.Label>
        <Col sm="9">
          <Form.Select
            className="rounded-0"
            defaultValue={officeId ? officeId : folder.officeId}
            key={folder.officeId}
            {...register("officeId", { required: true })}
          >
            <option value="" label={strings.formPlaceholderOffice} />
            {offices &&
              offices.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Select>
          <span className="text-danger error-message">{errors.officeId?.message}</span>
        </Col>
      </Form.Group>
    </Form>
  );
};
