import { Navbar, Row, Col } from "react-bootstrap";
import { LoginButton } from "../LoginButton/LoginButton";
import NCVIB from "../../assets/NCVIB.png";
import { useLogo } from "../../helpers/useLogo";

export const Header = () => {
  const { logo, isLoading } = useLogo();

  return (
    <Row>
      <Col className="p-0">
        <Navbar
          bg="primary"
          variant="dark"
          className="py-4 justify-content-between shadow-lg border-start border-primary"
        >
          <div className="d-flex flex-column flex-shrink-0 text-white ">
            <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none ">
              <img
                src={logo || isLoading ? logo : NCVIB}
                alt="logo"
                width="100"
                height="100"
                style={{
                  objectFit: "cover",

                  marginLeft: "3rem",
                }}
              />
            </div>
          </div>
          <LoginButton />
        </Navbar>
      </Col>
    </Row>
  );
};
