import { useRequest } from "../helpers/useRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useMessage } from "../helpers/useMessages";

const url = api.projects;

export const useGetAllProjects = () => {
  const { get } = useRequest();
  return useQuery<Project[]>(queryEnums.projects, () => get(`${url}`, null));
};

export const useGetAllProjectsByUser = (id?: string) => {
  const { get } = useRequest();
  return useQuery<Project[]>([queryEnums.projects, id], () => get(`${url}/user/${id}`), {
    enabled: !!id,
  });
};

export const useGetProjectById = (id?: string) => {
  const { get } = useRequest();

  return useQuery<Project>([queryEnums.projects_get, id], () => get(`${url}/${id}`), {
    enabled: !!id,
  });
};

export const useGetAllProjectStatus = () => {
  const { get } = useRequest();
  return useQuery(queryEnums.projects_status, () => get(`${url}/project-status`, null), {
    staleTime: 1000 * 60 * 5,
  });
};

export const useDeleteProject = () => {
  const { _delete } = useRequest();
  const queryClient = useQueryClient();
  const { sendRemoved } = useMessage();

  return useMutation((id?: string) => _delete(`${url}/${id}`), {
    onSuccess: () => {
      sendRemoved();
      queryClient.invalidateQueries(queryEnums.projects);
    },
  });
};

export const useAddProject = () => {
  const { post } = useRequest();
  const queryClient = useQueryClient();
  const { sendAdded } = useMessage();

  return useMutation((project: Project) => post(`${url}`, project), {
    onSuccess: (_result, project) => {
      sendAdded(project.name);
      queryClient.invalidateQueries(queryEnums.projects);
    },
  });
};

export const useUpdateProject = () => {
  const { put } = useRequest();
  const queryClient = useQueryClient();
  const { sendUpdated } = useMessage();

  return useMutation((project: Project) => put(`${url}/${project.id}`, project), {
    onSuccess: (_result, project) => {
      sendUpdated(project.name);
      queryClient.invalidateQueries(queryEnums.projects);
      queryClient.invalidateQueries(queryEnums.projects_get);
    },
  });
};

export const useGetProjectsByFolderId = (id?: string) => {
  const { get } = useRequest();
  const queryClient = useQueryClient();
  return useQuery<Project[]>([queryEnums.projectsByFolderId_get, id], () => get(`${url}/folder/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryEnums.projectsByFolderId_get);
    },
  });
};
