import { ReactNode } from "react";
import { Card, Col } from "react-bootstrap";

type Props = {
  children?: ReactNode;
};

export const DisplayItem = ({ children }: Props) => {
  return (
    <Col className="mt-4 text-white">
      <Card className="bg-success shadow rounded-1 h-100">{children}</Card>
    </Col>
  );
};
