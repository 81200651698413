import { ProjectsContainer } from "../../ProjectPage/ProjectsContainer/ProjectsContainer";
import { useHistory } from "react-router-dom";
import { useGetProjectsByFolderId } from "../../../services/projectService";
import { useGetFolderById } from "../../../services/folderService";
import { BackButton } from "../../../components/BackButton/BackButton";

type Props = {
  folderId?: string;
};

export const FolderDetailContainer = ({ folderId }: Props) => {
  const history = useHistory();
  const { data: projects } = useGetProjectsByFolderId(folderId);
  const { data: folder } = useGetFolderById(folderId);

  return (
    <div className="mt-3">
      <h4>{folder ? folder.name : ""}</h4>
      <BackButton
        onClick={() => {
          history.push("/folders");
        }}
      />
      <ProjectsContainer projects={projects || []} folderId={folderId} />
    </div>
  );
};
