import { ReactNode } from "react";
import { Suspense } from "react";
import { Container } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../ErrorFallback/ErrorFallback";
import { CustomLoader } from "../CustomLoader/CustomLoader";
import { useQueryErrorResetBoundary } from "react-query";

type Props = {
  children?: ReactNode;
};

export const Content = ({ children }: Props) => {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <Container fluid className="min-vh-100 content text-white bg-primary">
      <ErrorBoundary onReset={reset} FallbackComponent={ErrorFallback}>
        <Suspense fallback={<CustomLoader />}>{children}</Suspense>
      </ErrorBoundary>
    </Container>
  );
};
