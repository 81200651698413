import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetPasswordFormValidationSchema } from "../../../config/FormValidations";

type Props = {
  onSubmit(data: { password: string }): void;
};

export const ResetPasswordForm = ({ onSubmit }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ password: string }>({
    resolver: yupResolver(ResetPasswordFormValidationSchema),
  });
  return (
    <Form className="w-50" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type="password"
          {...register("password", { required: true })}
          placeholder="Enter password"
          autoComplete="on"
        />
        <span className="text-danger">{errors.password?.message}</span>
      </Form.Group>

      <Button variant="success" type="submit">
        Reset Password
      </Button>
    </Form>
  );
};
