import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { strings } from "../../config/string";
import { useAuth } from "../../helpers/useAuth";

export const LoginButton = () => {
  const { isAuthenticated, logout } = useAuth();
  if (isAuthenticated())
    return (
      <Button variant="primary" className="me-4 px-2" onClick={logout}>
        {strings.logout}
        <FontAwesomeIcon className="ms-2" icon={faSignOutAlt} />
      </Button>
    );
  return <></>;
};
