import React from "react";
import { IndexPage } from "../pages/IndexPage/IndexPage";
import { LoginPage } from "../pages/LoginPage/LoginPage";
import { OfficePage } from "../pages/OfficePage/OfficePage";
import { ProjectPage } from "../pages/ProjectPage/ProjectPage";
import { UserPage } from "../pages/UserPage/UserPage";
import { FolderPage } from "../pages/FolderPage/FolderPage";
import { FolderDetailPage } from "../pages/FolderDetailPage/FolderDetailPage";
import { OfficeDetailPage } from "../pages/OfficeDetailPage/OfficeDetailPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage/ResetPasswordPage";
import { ResetPasswordPageToken } from "../pages/SendResetPasswordTokenPage/SendResetPasswordTokenPage";
import { OrganisationPage } from "../pages/OrganisationPage/OrganisationPage";
import { OrganisationDetailPage } from "../pages/OrganisationDetailPage/OrganisationDetailPage";

interface Map {
  [key: string]: Route;
}

interface Route {
  path: string;
  component: React.FC;
  exact: boolean;
  protected: boolean;
}

export const routes: Map = {
  index: {
    path: `/`,
    component: IndexPage,
    exact: true,
    protected: true,
  },
  resetpassword: {
    path: `/resetpassword`,
    component: ResetPasswordPage,
    exact: true,
    protected: false,
  },
  resetpasswordtoken: {
    path: `/resetpasswordtoken`,
    component: ResetPasswordPageToken,
    exact: true,
    protected: false,
  },
  dashboard: {
    path: `/dashboard`,
    component: IndexPage,
    exact: true,
    protected: true,
  },
  offices: {
    path: `/offices`,
    component: OfficePage,
    exact: true,
    protected: true,
  },
  officeDetail: {
    path: `/offices/:id`,
    component: OfficeDetailPage,
    exact: true,
    protected: true,
  },
  organisations: {
    path: `/organisations`,
    component: OrganisationPage,
    exact: true,
    protected: true,
  },
  organisationDetail: {
    path: `/organisations/:id`,
    component: OrganisationDetailPage,
    exact: true,
    protected: true,
  },
  projects: {
    path: `/projects`,
    component: ProjectPage,
    exact: true,
    protected: true,
  },
  users: {
    path: `/users`,
    component: UserPage,
    exact: true,
    protected: true,
  },
  folders: {
    path: `/folders`,
    component: FolderPage,
    exact: true,
    protected: true,
  },
  folderDetail: {
    path: `/folders/:id`,
    component: FolderDetailPage,
    exact: true,
    protected: true,
  },
  login: {
    path: `/login`,
    component: LoginPage,
    exact: true,
    protected: false,
  },
};
