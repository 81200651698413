import { useState } from "react";
import { Row } from "react-bootstrap";
import { OrganisationItem } from "../OrganisationItem/OrganisationItem";
import { OrganisationCrudModals } from "../OrganisationCrudModals/OrganisationCrudModals";
import { AddEntityButton } from "../../../components/AddEntityButton/AddEntityButton";
import { strings } from "../../../config/string";
import { useGetAllOrganisations } from "../../../services/organisationService";

export const OrganisationContainer = () => {
  const { data } = useGetAllOrganisations();
  const [selectedOrganisationId, setSelectedOrganisationId] =
    useState<string>();
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  return (
    <div>
      <AddEntityButton
        onClick={() => setShowAdd(true)}
        text={strings.addNewOrganisation}
      />
      <OrganisationCrudModals
        showAdd={showAdd}
        organisationId={selectedOrganisationId}
        showDelete={showDelete}
        showEdit={showEdit}
        onAddClose={() => setShowAdd(false)}
        onEditClose={() => setShowEdit(false)}
        onDeleteClose={() => setShowDelete(false)}
      />
      <Row xs={1} sm={2} lg={3} xl={4} className="mt-3">
        {data &&
          data.map((organisation, index) => (
            <OrganisationItem
              key={`${organisation.id}_${index}`}
              organisation={organisation}
              onClickEdit={() => {
                setSelectedOrganisationId(organisation.id);
                setShowEdit(true);
              }}
              onClickDelete={() => {
                setSelectedOrganisationId(organisation.id);
                setShowDelete(true);
              }}
            />
          ))}
      </Row>
    </div>
  );
};
