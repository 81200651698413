import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../config/string";
import { OrganisationForm } from "../OrganisationForm/OrganisationForm";
import { EditEntityModal } from "../../../components/EditEntityModal/EditEntityModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrganisationFormValidationSchema } from "../../../config/FormValidations";
import {
  useGetOrganisationById,
  useUpdateOrganisation,
} from "../../../services/organisationService";

type Props = {
  organisationId?: string;
  handleClose: () => void;
  show: boolean;
};

export const EditOrganisationModal = ({
  organisationId,
  handleClose,
  show,
}: Props) => {
  const [value, setValue] = useState<Blob | null>(null);
  const { mutate: updateOrganisation } = useUpdateOrganisation();
  const { data } = useGetOrganisationById(organisationId);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<createOrganizationDTO>({
    resolver: yupResolver(OrganisationFormValidationSchema),
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  return (
    <div>
      <EditEntityModal
        handleClose={handleClose}
        show={show}
        message={strings.editOrganisationModalMessage}
        title={strings.editOrganisationModalTitle}
        onUpdate={(organisation) => {
          organisation.organization.id = organisationId;
          const organizationDto: createOrganizationDTO = {
            organization: { ...organisation.organization },
            defaultUser: null,
            file: value,
          };
          updateOrganisation(organizationDto);
          setTimeout(() => {
            setValue(null);
          }, 2000);
        }}
        data={data}
        handleSubmit={handleSubmit}
        reset={reset}
        entityForm={
          <OrganisationForm
            setValue={setValue}
            errors={errors}
            createOrganisationDTO={
              { defaultUser: null, organization: data as Organisation } ||
              ({} as createOrganizationDTO)
            }
            register={register}
            handleSubmit={handleSubmit}
          />
        }
      />
    </div>
  );
};
