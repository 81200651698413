import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { strings } from "../../../config/string";
import { useDeleteProject } from "../../../services/projectService";

type Props = {
  show: boolean;
  handleClose: () => void;
  projectId?: string;
};

export const DeleteProjectModal = ({ show, handleClose, projectId }: Props) => {
  const { mutate: deleteProject } = useDeleteProject();

  return (
    <ConfirmationModal
      title={strings.deleteProjectModalTitle}
      message={strings.deleteProjectModalMessage}
      show={show}
      handleConfirmation={() => {
        deleteProject(projectId);
        handleClose();
      }}
      handleClose={handleClose}
    />
  );
};
