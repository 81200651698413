import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../config/string";
import { OfficeForm } from "../OfficeForm/OfficeForm";
import {
  useGetOfficeById,
  useUpdateOffice,
} from "../../../services/officeService";
import { EditEntityModal } from "../../../components/EditEntityModal/EditEntityModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { OfficeFormValidationSchema } from "../../../config/FormValidations";

type Props = {
  officeId?: string;
  handleClose: () => void;
  show: boolean;
};

export const EditOfficeModal = ({ officeId, handleClose, show }: Props) => {
  const { mutate: updateOffice } = useUpdateOffice();
  const { data } = useGetOfficeById(officeId);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Office>({
    resolver: yupResolver(OfficeFormValidationSchema),
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  return (
    <div>
      <EditEntityModal
        handleClose={handleClose}
        show={show}
        message={strings.editOfficeModalMessage}
        title={strings.editOfficeModalTitle}
        onUpdate={updateOffice}
        data={data}
        handleSubmit={handleSubmit}
        reset={reset}
        entityForm={
          <OfficeForm
            errors={errors}
            office={data || ({} as Office)}
            register={register}
            handleSubmit={handleSubmit}
          />
        }
      />
    </div>
  );
};
