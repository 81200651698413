import { useParams } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { OrganisationDetailContainer } from "./OrganisationDetailContainer/OrganisationDetailContainer";

type OrganisationId = {
  id: string;
};
export const OrganisationDetailPage = () => {
  const { id } = useParams<OrganisationId>();
  return (
    <PageContent title={strings.folderPage}>
      <OrganisationDetailContainer organisationId={id} />
    </PageContent>
  );
};
