import { useRequest } from "../helpers/useRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useMessage } from "../helpers/useMessages";

const url = api.users;

export const useGetAllUsers = () => {
  const { get } = useRequest();
  return useQuery<User[]>(queryEnums.users, () => get(`${url}`, null));
};

export const useGetUserById = (id?: string) => {
  const { get } = useRequest();
  return useQuery<User>(
    [queryEnums.users_get, id],
    () => get(`${url}/${id}`, null),
    {
      enabled: !!id,
    }
  );
};

export const useDeleteUser = () => {
  const { _delete } = useRequest();
  const queryClient = useQueryClient();
  const { sendRemoved } = useMessage();

  return useMutation((id?: string) => _delete(`${url}/${id}`, null), {
    onSuccess: () => {
      sendRemoved();
      queryClient.invalidateQueries(queryEnums.users);
    },
  });
};

export const useAddUser = () => {
  const { post } = useRequest();
  const queryClient = useQueryClient();
  const { sendAdded } = useMessage();

  return useMutation((user: User) => post(`${url}`, user), {
    onSuccess: (_result, user) => {
      sendAdded(`${user.userName} (${user.email})`);
      queryClient.invalidateQueries(queryEnums.users);
    },
  });
};

export const useSendPasswordResetEmaill = () => {
  const { post } = useRequest();
  const queryClient = useQueryClient();
  const { sendCustom } = useMessage();

  return useMutation(
    (email: string) => post(`${url}/passwordReset/${email}`, {}),
    {
      onSuccess: (_result) => {
        debugger;
        sendCustom(`Email sent`);
        queryClient.invalidateQueries(queryEnums.users);
      },
      onError: (_result) => {
        debugger;
        sendCustom("Fail");
      },
    }
  );
};

export const useSendPasswordReset = () => {
  const { post } = useRequest();
  const queryClient = useQueryClient();
  const { sendCustom } = useMessage();

  return useMutation(
    (arg: { email: string; token: string; password: string }) =>
      post(`${url}/passwordResetToken`, arg),
    {
      onSuccess: (_result) => {
        debugger;
        sendCustom(`Password Updated`);
        queryClient.invalidateQueries(queryEnums.users);
      },
      onError: (_result) => {
        debugger;
        sendCustom(`Fail`);
      },
    }
  );
};

export const useUpdateUser = () => {
  const { put } = useRequest();
  const queryClient = useQueryClient();
  const { sendUpdated } = useMessage();
  return useMutation((user: User) => put(`${url}/${user.id}`, user), {
    onSuccess: (_result, user) => {
      sendUpdated(`${user.userName} (${user.email})`);
      queryClient.invalidateQueries(queryEnums.users);
      queryClient.invalidateQueries(queryEnums.users_get);
    },
  });
};
