import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { strings } from "../../../config/string";
import { useDeleteOffice } from "../../../services/officeService";

type Props = {
  show: boolean;
  handleClose: () => void;
  officeId?: string;
};

export const DeleteOfficeModal = ({ show, handleClose, officeId }: Props) => {
  const { mutate: deleteOffice } = useDeleteOffice();

  return (
    <ConfirmationModal
      title={strings.deleteOfficeModalTitle}
      message={strings.deleteOfficeModalMessage}
      show={show}
      handleConfirmation={() => {
        deleteOffice(officeId);
        handleClose();
      }}
      handleClose={handleClose}
    />
  );
};
