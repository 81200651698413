import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

type Props = {
  onClick: () => void;
};

export const DeleteButton = ({ onClick }: Props) => {
  return (
    <Button aria-label="delete" variant="link" className="text-warning" onClick={onClick}>
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  );
};
