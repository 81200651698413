import { useState } from "react";
import { Row } from "react-bootstrap";
import { AddEntityButton } from "../../../components/AddEntityButton/AddEntityButton";
import { strings } from "../../../config/string";
import { ProjectCrudModals } from "../ProjectCrudModals/ProjectCrudModals";
import { ProjectItem } from "../ProjectItem/ProjectItem";

type Props = {
  projects: Project[];
  folderId?: string;
};

export const ProjectsContainer = ({ projects, folderId }: Props) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [projectId, setProjectId] = useState<string>();

  return (
    <div>
      <AddEntityButton onClick={() => setShowAdd(true)} text={strings.addNewProject} />
      <ProjectCrudModals
        showDelete={showDelete}
        showAdd={showAdd}
        showEdit={showEdit}
        folderId={folderId}
        onAddClose={() => setShowAdd(false)}
        onDeleteClose={() => setShowDelete(false)}
        onEditClose={() => setShowEdit(false)}
        projectId={projectId}
      />
      <Row xs={1} sm={2} lg={3} xl={4} className="mt-3">
        {projects &&
          projects.map((project, index) => (
            <ProjectItem
              key={`${project.id}_${index}`}
              project={project}
              onClickEdit={() => {
                setProjectId(project.id);
                setShowEdit(true);
              }}
              onClickDelete={() => {
                setProjectId(project.id);
                setShowDelete(true);
              }}
            />
          ))}
      </Row>
    </div>
  );
};
