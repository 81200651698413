import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { strings } from "../../../config/string";
import { useAddOffice } from "../../../services/officeService";
import { OfficeForm } from "../OfficeForm/OfficeForm";
import { OfficeFormValidationSchema } from "../../../config/FormValidations";

type Props = {
  show: boolean;
  handleClose: () => void;
};

export const AddOfficeModal = ({ show, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Office>({
    resolver: yupResolver(OfficeFormValidationSchema),
  });
  const { mutate: addOffice } = useAddOffice();

  const handleConfirmation = (office: Office) => {
    debugger;

    const user = localStorage.getItem("user");
    const item = JSON.parse(user || "");
    addOffice({ ...office, createdBy: item?.email || "UNKNOWN" });
    handleClose();
    reset();
  };

  const handleModalClose = () => {
    handleClose();
    reset();
  };

  const office = {} as Office;

  return (
    <div>
      <ConfirmationModal
        show={show}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        message={strings.newOfficeModalMessage}
        title={strings.newOfficeModalTitle}
      >
        <OfficeForm
          errors={errors}
          office={office}
          register={register}
          handleSubmit={handleSubmit}
        />
      </ConfirmationModal>
    </div>
  );
};
