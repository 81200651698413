import { useSetRecoilState } from "recoil";
import { strings } from "../config/string";
import { messageAtom } from "../recoil/auth/atom";

export const useMessage = () => {
  const setMessage = useSetRecoilState(messageAtom);

  const sendUpdated = (entity = "") => {
    setMessage(`${strings.messageToastUpdated} ${entity}`);
  };
  const sendAdded = (entity = "") => {
    setMessage(`${strings.messageToastAdded} ${entity}`);
  };
  const sendRemoved = (entity = "") => {
    setMessage(`${strings.messageToastDeleted} ${entity}`);
  };

  const sendCustom = (entity = "") => {
    setMessage(`${entity}`);
  };

  return {
    sendUpdated,
    sendAdded,
    sendRemoved,
    sendCustom,
  };
};
