import { Card, Badge } from "react-bootstrap";
import { EditButton } from "../../../components/EditButton/EditButton";
import { DeleteButton } from "../../../components/DeleteButton/DeleteButton";
import { DisplayItem } from "../../../components/DisplayItem/DisplayItem";

type Props = {
  user: User;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

export const UserItem = ({ user, onClickEdit, onClickDelete }: Props) => {
  return (
    <DisplayItem>
      <Card.Body>
        <Card.Title>{user.userName}</Card.Title>
        <Card.Subtitle className="mb-2 text-white opacity-75">
          {user.email}
        </Card.Subtitle>
        <Card.Text>
          {user.offices &&
            user.offices.map((office) => (
              <Badge
                key={office.id}
                className="bg-primary border-dark text-white p-2 opacity-100 mb-2 me-2"
              >
                {office.name}
              </Badge>
            ))}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="pt-0">
        <EditButton onClick={onClickEdit} />
        <DeleteButton onClick={onClickDelete} />
        <span className=" btn text-white float-end fw-lighter">
          {user.role}
        </span>
      </Card.Footer>
    </DisplayItem>
  );
};
