import { useParams } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { FolderDetailContainer } from "./FolderDetailContainer/FolderDetailContainer";

type FolderId = {
  id: string;
};
export const FolderDetailPage = () => {
  const { id } = useParams<FolderId>();
  return (
    <PageContent title={strings.projectPage}>
      <FolderDetailContainer folderId={id} />
    </PageContent>
  );
};
