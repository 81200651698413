import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../config/string";
import { EditEntityModal } from "../../../components/EditEntityModal/EditEntityModal";
import { useGetUserById, useUpdateUser } from "../../../services/userService";
import { UserForm } from "../UserForm/UserForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserFormValidationSchema } from "../../../config/FormValidations";
import { useGetAllOffices } from "../../../services/officeService";

type Props = {
  show: boolean;
  handleClose: () => void;
  userId?: string;
};

export const EditUserModal = ({ show, handleClose, userId }: Props) => {
  const { data } = useGetUserById(userId);
  const { mutate: updateUser } = useUpdateUser();
  const { data: offices } = useGetAllOffices();
  const responseError = "";

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(UserFormValidationSchema),
  });

  return (
    <div>
      <EditEntityModal
        show={show}
        handleClose={handleClose}
        message={strings.editUserModalMessage}
        title={strings.editUserModalTitle}
        onUpdate={(data: User) => {
          updateUser(data);
        }}
        data={data}
        handleSubmit={handleSubmit}
        reset={reset}
        entityForm={
          <UserForm
            offices={offices || []}
            user={data || ({} as User)}
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            setValue={setValue}
            message={responseError}
          />
        }
      />
    </div>
  );
};
