import { EditOrganisationModal } from "../EditOrganisationModal/EditOrganisationModal";
import { DeleteOrganisationModal } from "../DeleteOrganisationModal/DeleteOrganisationModal";
import { AddOrganisationModal } from "../AddOrganisationModal/AddOrganisationModal";

type Props = {
  showAdd: boolean;
  showEdit: boolean;
  showDelete: boolean;
  onEditClose: () => void;
  onDeleteClose: () => void;
  onAddClose: () => void;
  organisationId?: string;
};

export const OrganisationCrudModals = ({
  showAdd,
  onAddClose,
  showEdit,
  onEditClose,
  showDelete,
  onDeleteClose,
  organisationId,
}: Props) => {
  return (
    <>
      <EditOrganisationModal
        show={showEdit}
        organisationId={organisationId}
        handleClose={onEditClose}
      />
      <DeleteOrganisationModal
        show={showDelete}
        organisationId={organisationId}
        handleClose={onDeleteClose}
      />
      <AddOrganisationModal show={showAdd} handleClose={onAddClose} />
    </>
  );
};
