import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { strings } from "../../../config/string";
import { ProjectForm } from "../ProjectForm/ProjectForm";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { useAddProject } from "../../../services/projectService";
import { useGetAllOffices, useGetOfficesByUserId } from "../../../services/officeService";
import { useGetAllProjectStatus } from "../../../services/projectService";
import { useGetAllFolders } from "../../../services/folderService";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProjectFormValidationSchema } from "../../../config/FormValidations";
import { authAtom } from "../../../recoil/auth/atom";
import Roles from "../../../constants/userRoles";

type Props = {
  show: boolean;
  handleClose: () => void;
  folderId?: string;
};

export const AddProjectModal = ({ show, folderId, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ProjectFormValidationSchema),
    defaultValues: { projectTempId: null },
  });
  const { mutate: addProject } = useAddProject();
  const { data: offices } = useGetAllOffices();
  const { data: projectStatus } = useGetAllProjectStatus();
  const { data: folders } = useGetAllFolders();

  const auth = useRecoilValue(authAtom);
  const { data: userOffices } = useGetOfficesByUserId(auth.id);
  const userByRoles = auth.roles.some((role: any) => role === Roles.Admin);

  const handleConfirmation = (project: Project) => {
    addProject(project);
    handleClose();
    reset();
  };

  const project = {} as Project;
  const handleModalClose = () => {
    handleClose();
    reset();
  };

  return (
    <div>
      <ConfirmationModal
        show={show}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        message={strings.newProjectModalMessage}
        title={strings.newProjectModalTitle}
      >
        <ProjectForm
          projectStatus={projectStatus}
          offices={userByRoles ? offices || [] : userOffices || []}
          errors={errors}
          reset={reset}
          setValue={setValue}
          folders={folders || []}
          folderId={folderId}
          project={project}
          register={register}
          handleSubmit={handleSubmit}
        />
      </ConfirmationModal>
    </div>
  );
};
