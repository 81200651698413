import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { strings } from "../../../config/string";
import { useDeleteUser } from "../../../services/userService";

type Props = {
  show: boolean;
  handleClose: () => void;
  userId?: string;
};

export const DeleteUserModal = ({ show, handleClose, userId }: Props) => {
  const { mutate: deleteUser } = useDeleteUser();

  return (
    <ConfirmationModal
      title={strings.deleteUserModalTitle}
      message={strings.deleteUserModalMessage}
      show={show}
      handleConfirmation={() => {
        deleteUser(userId);
        handleClose();
      }}
      handleClose={handleClose}
    />
  );
};
