import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { EditEntityModal } from "../../../components/EditEntityModal/EditEntityModal";
import { strings } from "../../../config/string";
import { useGetProjectById, useUpdateProject, useGetAllProjectStatus } from "../../../services/projectService";
import { useGetAllOffices, useGetOfficesByUserId } from "../../../services/officeService";
import { useGetAllFolders } from "../../../services/folderService";
import { ProjectForm } from "../ProjectForm/ProjectForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProjectFormValidationSchema } from "../../../config/FormValidations";
import { authAtom } from "../../../recoil/auth/atom";
import Roles from "../../../constants/userRoles";

type Props = {
  projectId?: string;
  handleClose: () => void;
  show: boolean;
  folderId?: string;
};

export const EditProjectModal = ({ projectId, show, folderId, handleClose }: Props) => {
  const { mutate: updateProject } = useUpdateProject();
  const { data } = useGetProjectById(projectId);
  const { data: offices } = useGetAllOffices();
  const { data: projectStatus } = useGetAllProjectStatus();
  const { data: folders } = useGetAllFolders();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ProjectFormValidationSchema),
    defaultValues: { projectTempId: null },
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const auth = useRecoilValue(authAtom);
  const { data: userOffices } = useGetOfficesByUserId(auth.id);
  const userByRoles = auth.roles.some((role: any) => role === Roles.Admin);

  return (
    <div>
      <EditEntityModal
        show={show}
        handleClose={handleClose}
        message={strings.editProjectModalMessage}
        title={strings.editProjectModalTitle}
        onUpdate={updateProject}
        data={data}
        handleSubmit={handleSubmit}
        reset={reset}
        entityForm={
          <ProjectForm
            projectStatus={projectStatus}
            offices={userByRoles ? offices || [] : userOffices || []}
            folders={folders || []}
            errors={errors}
            reset={reset}
            folderId={folderId}
            setValue={setValue}
            project={data || ({} as Project)}
            register={register}
            handleSubmit={handleSubmit}
          />
        }
      />
    </div>
  );
};
