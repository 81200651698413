import React from "react";
import { Button, Modal } from "react-bootstrap";
import { strings } from "../../config/string";

type Props = {
  title: string;
  message: string;
  show: boolean;
  handleClose: (value: boolean) => void;
  handleConfirmation: () => void;
  children?: React.ReactNode;
};

export const ConfirmationModal = ({ title, message, show, handleClose, handleConfirmation, children }: Props) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">{message}</div>
        <div>{children}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleClose(false)}>
          {strings.confirmationModalClose}
        </Button>
        <Button variant="primary" onClick={handleConfirmation}>
          {strings.confirmationModalAccept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
