import { Badge, Card, Button } from "react-bootstrap";
import { DeleteButton } from "../../../components/DeleteButton/DeleteButton";
import { DisplayItem } from "../../../components/DisplayItem/DisplayItem";
import { EditButton } from "../../../components/EditButton/EditButton";
import { strings } from "../../../config/string";

type Props = {
  folder: Folder;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickProjects: () => void;
};

export const FolderItem = ({ folder, onClickEdit, onClickDelete, onClickProjects }: Props) => {
  return (
    <DisplayItem>
      <Card.Body>
        <Card.Title>{folder.name}</Card.Title>
        <Card.Text>
          <Badge className="bg-primary border-dark text-white p-2 opacity-100 ">{folder.officeName}</Badge>
        </Card.Text>
      </Card.Body>
      <Card.Footer className="pt-0">
        <div className="d-flex justify-content-between">
          <EditButton onClick={onClickEdit} />
          <DeleteButton onClick={onClickDelete} />
          <Button
            className="ms-auto float-right btn bg-primary border-dark text-white p-2 opacity-100 badge mt-2"
            variant="warning"
            onClick={onClickProjects}
          >
            {strings.showProjects}
          </Button>
        </div>
      </Card.Footer>
    </DisplayItem>
  );
};
