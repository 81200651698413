import { Button } from "react-bootstrap";
import { FoldersContainer } from "../../FolderPage/FoldersContainer/FoldersContainer";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useGetFolderByOrganisationId } from "../../../services/folderService";
import { strings } from "../../../config/string";
import { useGetOrganisationById } from "../../../services/organisationService";
type Props = {
  organisationId?: string;
};

export const OrganisationDetailContainer = ({ organisationId }: Props) => {
  const history = useHistory();

  const { data: folders } = useGetFolderByOrganisationId(organisationId);
  const { data: organisation } = useGetOrganisationById(organisationId);

  return (
    <div className="mt-3">
      <h4>{organisation ? organisation.name : ""}</h4>
      <Button
        onClick={() => {
          history.push("/organisations");
        }}
        className="ms-auto mb-2"
        variant="warning"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
        {strings.back}
      </Button>
      <FoldersContainer
        folders={folders || []}
        organisationId={organisationId}
      />
    </div>
  );
};
