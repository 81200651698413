import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { strings } from "../../../config/string";
import { useDeleteFolder } from "../../../services/folderService";

type Props = {
  show: boolean;
  handleClose: () => void;
  folderId?: string;
};

export const DeleteFolderModal = ({ show, handleClose, folderId }: Props) => {
  const { mutate: deleteFolder } = useDeleteFolder();

  return (
    <ConfirmationModal
      title={strings.deleteFolderModalTitle}
      message={strings.deleteFolderModalMessage}
      show={show}
      handleConfirmation={() => {
        deleteFolder(folderId);
        handleClose();
      }}
      handleClose={handleClose}
    />
  );
};
