import { EditProjectModal } from "../EditProjectModal/EditProjectModal";
import { AddProjectModal } from "../AddProjectModal/AddProjectModal";
import { DeleteProjectModal } from "../DeleteProjectModal/DeleteProjectModal";

type Props = {
  showAdd: boolean;
  showEdit: boolean;
  showDelete: boolean;
  onAddClose: () => void;
  onEditClose: () => void;
  onDeleteClose: () => void;
  projectId?: string;
  folderId?: string;
};

export const ProjectCrudModals = ({
  showAdd,
  onAddClose,
  showEdit,
  onEditClose,
  showDelete,
  onDeleteClose,
  projectId,
  folderId,
}: Props) => {
  return (
    <>
      <EditProjectModal show={showEdit} projectId={projectId} folderId={folderId} handleClose={onEditClose} />
      <DeleteProjectModal show={showDelete} projectId={projectId} handleClose={onDeleteClose} />
      <AddProjectModal show={showAdd} folderId={folderId} handleClose={onAddClose} />
    </>
  );
};
