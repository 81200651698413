import { Badge, Card } from "react-bootstrap";
import { DeleteButton } from "../../../components/DeleteButton/DeleteButton";
import { DisplayItem } from "../../../components/DisplayItem/DisplayItem";
import { EditButton } from "../../../components/EditButton/EditButton";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  project: Project;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

export const ProjectItem = ({ project, onClickEdit, onClickDelete }: Props) => {
  return (
    <DisplayItem>
      <Card.Body>
        <Card.Title>{project.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-white opacity-75">{project.description}</Card.Subtitle>
        <Card.Text>
          <Badge className="border-dark text-white p-2 opacity-100 me-2 mb-2 shadow bg-dark rounded ">
            <FontAwesomeIcon icon={faBuilding} className="me-1 text-white" />
            {project.officeName}
          </Badge>
          <Badge className="border-dark text-white p-2 opacity-100 shadow bg-dark rounded">
            <FontAwesomeIcon icon={faFolderOpen} className="me-1 text-white" />
            {project.folderName}
          </Badge>
        </Card.Text>
      </Card.Body>
      <Card.Footer className="pt-0">
        <EditButton onClick={onClickEdit} />
        <DeleteButton onClick={onClickDelete} />
        <div className="text-white float-end fw-bold mt-2">{project.projectStatus}</div>
      </Card.Footer>
    </DisplayItem>
  );
};
