import { Col, Form, Row } from "react-bootstrap";
import { UseFormRegister } from "react-hook-form";
import { strings } from "../../../config/string";
import { useEffect, useState } from "react";

type Props = {
  project: Project;
  handleSubmit: any;
  register: UseFormRegister<Project>;
  offices: Office[];
  projectStatus: ProjectStatus[];
  errors: any;
  folders: Folder[];
  reset?: any;
  setValue?: any;
  folderId?: string;
};

export const ProjectForm = ({
  project,
  handleSubmit,
  register,
  offices,
  projectStatus,
  errors,
  folders,
  setValue,
  folderId,
}: Props) => {
  const officeId = folderId ? folders.filter((item) => item.id === folderId)[0].officeId : project.officeId;
  const [folderData, setFolderData] = useState(folders.filter((x) => x.officeId === officeId));

  const handleSelectOnChange = (data: any) => {
    setValue("folderId", "");
    setFolderData(folders.filter((x) => x.officeId === data.target.value));
  };

  useEffect(() => {
    setValue("folderId", project.folderId);
    setValue("projectStatusId", project.projectStatusId);
  }, [project.folderId, setValue, project.projectStatusId]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4">
          {strings.formFieldName}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            placeholder={strings.formPlaceholderName}
            defaultValue={project.name}
            {...register("name", { required: true, maxLength: 80 })}
          />
          <span className="text-danger error-message">{errors.name?.message}</span>
        </Col>
      </Form.Group>
      {/* <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4">
          {strings.formFieldProjectNumber}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            placeholder={strings.formPlaceholderProjectNumber}
            defaultValue={project.projectNumber}
            {...register("projectNumber", { required: true, maxLength: 80 })}
          />
          <span className="text-danger">{errors.projectNumber?.message}</span>
        </Col>
      </Form.Group> */}

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4">
          {strings.formFieldDesc}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            placeholder={strings.formPlaceholderDesc}
            defaultValue={project.description}
            as="textarea"
            {...register("description", { required: true, maxLength: 300 })}
          />
          <span className="text-danger error-message">{errors.description?.message}</span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4">
          {strings.formFieldOffice}
        </Form.Label>
        <Col sm="8">
          <Form.Select
            className="rounded"
            defaultValue={officeId}
            key={project.officeId}
            data-testid="office-select"
            {...register("officeId", { required: true, onChange: handleSelectOnChange })}
            onChange={handleSelectOnChange}
          >
            <option value="" label={strings.formPlaceholderOffice} />
            {offices &&
              offices.map((item) => (
                <option key={item.id} value={item.id} data-testid="officeSelect-option">
                  {item.name}
                </option>
              ))}
          </Form.Select>
          <span className="text-danger error-message">{errors.officeId?.message}</span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4">
          {strings.formFieldFolderName}
        </Form.Label>
        <Col sm="8">
          <Form.Select
            className="rounded"
            defaultValue={folderId ? folderId : project.folderId}
            key={project.folderId}
            data-testid="folder-select"
            {...register("folderId", { required: true })}
          >
            <option value="" label={strings.formPlaceholderFolderName} />
            {folderData &&
              folderData.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name} - {item.officeName}
                </option>
              ))}
          </Form.Select>
          <span className="text-danger error-message">{errors.folderId?.message}</span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4">
          {strings.formFieldProjectStatus}
        </Form.Label>
        <Col sm="8">
          <Form.Select
            className="rounded"
            defaultValue={project.projectStatusId}
            data-testid="projectstatus-select"
            {...register("projectStatusId", { required: true })}
          >
            <option value="" label={strings.formPlaceholderProjectStatus} />
            {projectStatus &&
              projectStatus.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Select>
          <span className="text-danger error-message">{errors.projectStatusId?.message}</span>
        </Col>
      </Form.Group>
    </Form>
  );
};
