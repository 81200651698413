import { useRequest } from "../helpers/useRequest";
import { useMutation } from "react-query";
import { api } from "../config/apiEndpoints";

const url = api.auth;

export const useAuthLogin = () => {
  const { post } = useRequest();

  return useMutation((user: LoginUser) => post(`${url}/login`, user));
};

export const useRefreshToken = () => {
  const { post } = useRequest();

  return useMutation((id: string) => post(`${url}/refresh-token?&id=${id}`, null));
};
