import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { Dashboard } from "./Dashboard/Dashboard";

export const IndexPage = () => {
  return (
    <PageContent title={strings.indexPage}>
      <Dashboard />
    </PageContent>
  );
};
