import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import { routes } from "./config/routes";
import { useAuth } from "./helpers/useAuth";
import "./styles/global.scss";

function App() {
  const { isAuthenticated } = useAuth();
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {Object.entries(routes).map(([key, value]) => {
            return (
              <Route
                key={`route_${key}`}
                path={value.path}
                exact={value.exact}
                render={() => {
                  if (!isAuthenticated() && value.protected) {
                    return <Redirect to={{ pathname: "/login" }} />;
                  }
                  return <value.component />;
                }}
              />
            );
          })}
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
