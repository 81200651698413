import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { strings } from "../../../config/string";
import { OrganisationForm } from "../OrganisationForm/OrganisationForm";
import { OrganisationFormValidationSchema } from "../../../config/FormValidations";
import { useAddOrganisation } from "../../../services/organisationService";
import { useState } from "react";

type Props = {
  show: boolean;
  handleClose: () => void;
};

export const AddOrganisationModal = ({ show, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<createOrganizationDTO>({
    resolver: yupResolver(OrganisationFormValidationSchema),
  });
  const [value, setValue] = useState<Blob | null>(null);
  const { mutate: addOrganisation } = useAddOrganisation();

  const handleConfirmation = (organisation: createOrganizationDTO) => {
    if (
      !organisation.defaultUser?.email ||
      !organisation.defaultUser.userName ||
      !value
    ) {
      alert("Missing userName/email/logo");
      return;
    }
    addOrganisation({ ...organisation, file: value });
    handleClose();
    reset();
    setTimeout(() => {
      setValue(null);
    }, 2000);
  };

  const handleModalClose = () => {
    handleClose();
    reset();
  };

  const organisation = {} as Organisation;

  return (
    <div>
      <ConfirmationModal
        show={show}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        message={strings.newOrganisationModalMessage}
        title={strings.newOrganisationModalTitle}
      >
        <OrganisationForm
          setValue={setValue}
          create
          errors={errors}
          createOrganisationDTO={{
            defaultUser: null,
            organization: organisation,
          }}
          register={register}
          handleSubmit={handleSubmit}
        />
      </ConfirmationModal>
    </div>
  );
};
