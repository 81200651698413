import { useParams } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { strings } from "../../config/string";
import { OfficeDetailContainer } from "../OfficeDetailPage/OfficeDetailContainer/OfficeDetailContainer";

type OfficeId = {
  id: string;
};
export const OfficeDetailPage = () => {
  const { id } = useParams<OfficeId>();
  return (
    <PageContent title={strings.folderPage}>
      <OfficeDetailContainer officeId={id} />
    </PageContent>
  );
};
