import { Col, Form, Row } from "react-bootstrap";
import { UseFormRegister } from "react-hook-form";
import { strings } from "../../../config/string";

type Props = {
  office: Office;
  handleSubmit: any;
  register: UseFormRegister<Office>;
  errors: any;
};

export const OfficeForm = ({
  office,
  handleSubmit,
  register,
  errors,
}: Props) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          {strings.formFieldName}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            placeholder={strings.formPlaceholderName}
            defaultValue={office.name}
            {...register("name", { required: true, maxLength: 80 })}
          />
          <span className="text-danger error-message">
            {errors.name?.message}
          </span>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          {strings.formFieldDesc}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            placeholder={strings.formPlaceholderDesc}
            defaultValue={office.description}
            {...register("description", { required: true, maxLength: 300 })}
          />
          <span className="text-danger error-message">
            {errors.description?.message}
          </span>
        </Col>
      </Form.Group>
    </Form>
  );
};
