import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  icon: IconProp;
  count: number;
  title: string;
};

export const DashItem = ({ icon, count, title }: Props) => {
  return (
    <div className="bg-success col-md-5 col-lg-3 d-flex  p-4 text-white mb-4  me-4 shadow rounded-1">
      <div>
        <div className="count fw-bold">{count}</div>
        <div className="text-warning">{title}</div>
      </div>
      <FontAwesomeIcon icon={icon} size="6x" className="ms-auto text-primary align-self-center" />
    </div>
  );
};
