import { useRequest } from "../helpers/useRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "../config/apiEndpoints";
import { queryEnums } from "../config/queryEnums";
import { useMessage } from "../helpers/useMessages";

const url = api.organisations;

export const useGetAllOrganisations = () => {
  const { get } = useRequest();
  return useQuery<Organisation[]>(queryEnums.organisations, () => get(`${url}`));
};

export const useAddOrganisation = () => {
  const { post } = useRequest();
  const queryClient = useQueryClient();
  const { sendAdded } = useMessage();

  return useMutation((organisation: createOrganizationDTO) => {
    const formData = new FormData();
    formData.append('Organization.Description', organisation.organization.description || '');
    formData.append('Organization.Name', organisation.organization.name || '');
    formData.append('Organization.Logo', organisation.organization.logo || '');
    formData.append('DefaultUser.userName', organisation.defaultUser?.userName || '');
    formData.append('DefaultUser.email', organisation.defaultUser?.email || '');
    if (organisation.file) {
      const prom = organisation.file!;
      // @ts-ignore
      formData.append('File', prom, prom.name);
    }

    console.log(formData.values(), 'FOrm Data', organisation, `${url}`);
    return post(`${url}`, formData, false, true)
  }, {
    onSuccess: (_result, Organisation) => {
      sendAdded(Organisation.organization.name);
      queryClient.invalidateQueries(queryEnums.organisations);
    },
  });
};

export const useDeleteOrganisation = () => {
  const { _delete } = useRequest();
  const queryClient = useQueryClient();
  const { sendRemoved } = useMessage();
  return useMutation((id?: string) => _delete(`${url}/${id}`), {
    onSuccess: () => {
      sendRemoved();
      queryClient.invalidateQueries(queryEnums.organisations);
    },
  });
};

export const useGetOrganisationById = (id?: string) => {
  const { get } = useRequest();
  return useQuery<Organisation>([queryEnums.Organisations_get, id], () => get(`${url}/${id}`), {
    enabled: !!id,
  });
};


export const useUpdateOrganisation = () => {
  const { post } = useRequest();
  const queryClient = useQueryClient();
  const { sendUpdated } = useMessage();
  return useMutation(async (createOrganisationDTO: createOrganizationDTO) => {
    const formData = new FormData();

    formData.append('Name', 'Ahmad');

    formData.append('Organization.Id', createOrganisationDTO.organization.id || '');
    formData.append('Organization.Description', createOrganisationDTO.organization.description || '');
    formData.append('Organization.Name', createOrganisationDTO.organization.name || '');
    formData.append('Organization.Logo', createOrganisationDTO.organization.logo || '');
    formData.append('Organization.OrganizationNumber', createOrganisationDTO.organization?.organizationNumber || '');
    if (createOrganisationDTO.file) {
      const prom = createOrganisationDTO.file!;
      // @ts-ignore
      formData.append('File', prom, prom.name);
    }
    console.log(formData, 'FOrm Data', createOrganisationDTO, `${url}`);
    const objects = Object.fromEntries(formData)

    console.log(objects, 'Objects', createOrganisationDTO, `${url}`);
    return post(`${url}`, formData, false, true)
  }, {
    onSuccess: (_result, organisation) => {
      sendUpdated(organisation.organization.name);
      queryClient.invalidateQueries(queryEnums.organisations);
      queryClient.invalidateQueries(queryEnums.Organisations_get);
    },
  });
};


