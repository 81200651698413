import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import App from "./App";
import { queryClient } from "./config/queryClient";
import { CustomLoader } from "./components/CustomLoader/CustomLoader";

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Suspense fallback={() => <CustomLoader />}>
          <App />
        </Suspense>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
