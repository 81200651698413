import { faBuilding, faProjectDiagram, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useGetAllOffices } from "../../../services/officeService";
import { useGetAllProjectsByUser } from "../../../services/projectService";
import { useGetAllUsers } from "../../../services/userService";
import { DashItem } from "../DashItem/DashItem";
import "./Dashboard.scss";
import { authAtom } from "../../../recoil/auth/atom";
import { useRecoilValue } from "recoil";
import Roles from "../../../constants/userRoles";

export const Dashboard = () => {
  const { data: users } = useGetAllUsers();
  const { data: offices } = useGetAllOffices();
  const auth = useRecoilValue(authAtom);
  const { data: projects } = useGetAllProjectsByUser(auth.id);

  return (
    <div className="row text-white dashboard mt-4">
      {auth.roles.some((role: any) => role === Roles.Admin) && offices && (
        <DashItem icon={faBuilding} count={offices.length} title={"offices"} />
      )}
      {projects && <DashItem icon={faProjectDiagram} count={projects.length} title={"projects"} />}
      {auth.roles.some((role: any) => role === Roles.Admin) && users && (
        <DashItem icon={faUsers} count={users.length} title={"users"} />
      )}
    </div>
  );
};
