import React from "react";
import { PageContent } from "../../components/PageContent/PageContent";
import { useSendPasswordResetEmaill } from "../../services/userService";
import { ResetPasswordTokenForm } from "./ResetPasswordTokenForm/ResetPasswordTokenForm";

export function ResetPasswordPageToken(): React.ReactElement {
  const { mutateAsync } = useSendPasswordResetEmaill();

  return (
    <PageContent title={"Enter your registered email"}>
      <ResetPasswordTokenForm
        onSubmit={async (e) => {
          debugger;
          await mutateAsync(e.email);
        }}
      />
    </PageContent>
  );
}
