export const ROLES = [
  { value: "User", label: "User" },
  { value: "Admin", label: "Admin" },
];

export const superAdmins = [
  "admin@nitro.com",
  "christer.holmberg@nitroconsult.se",
  "mattias.aslund@nitroconsult.se",
  "philip.brandstrom@nitroconsult.se",
  "simon.degerman@nitroconsult.se",
  "ahmad@crossplatform.se",
  "emil@crossplatform.se",
  "milad@crossplatform.se",
];
