import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetPasswordTokenFormValidationSchema } from "../../../config/FormValidations";

type Props = {
  onSubmit(data: { email: string }): void;
};

export const ResetPasswordTokenForm = ({ onSubmit }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: yupResolver(ResetPasswordTokenFormValidationSchema),
  });
  return (
    <Form className="w-50" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          {...register("email", { required: true })}
          placeholder="Enter Email"
          autoComplete="on"
        />
        <span className="text-danger">{errors.email?.message}</span>
      </Form.Group>

      <Button variant="success" type="submit">
        Send Reset Password Email
      </Button>
    </Form>
  );
};
