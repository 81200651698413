import { EditUserModal } from "../EditUserModal/EditUserModal";
import { DeleteUserModal } from "../DeleteUserModal/DeleteUserModal";
import { AddUserModal } from "../AddUserModal/AddUserModal";

type Props = {
  showAdd: boolean;
  onAddClose: () => void;
  showEdit: boolean;
  onEditClose: () => void;
  showDelete: boolean;
  onDeleteClose: () => void;
  userId?: string;
};

export const UserCrudModals = ({
  showAdd,
  onAddClose,
  showEdit,
  onEditClose,
  showDelete,
  onDeleteClose,
  userId,
}: Props) => {
  return (
    <>
      <EditUserModal show={showEdit} userId={userId} handleClose={onEditClose} />
      <DeleteUserModal show={showDelete} userId={userId} handleClose={onDeleteClose} />
      <AddUserModal show={showAdd} handleClose={onAddClose} />
    </>
  );
};
