export const queryEnums = {
  users: "getAllUsers",
  users_get: "getUserById",
  offices: "getAllOffices",
  offices_get: "getOfficeById",
  organisations: "getAllOrganisations",
  Organisations_get: "getOrganisationById",
  projects: "getAllProjects",
  projects_get: "getProjectById",
  projectsByFolderId_get: "getProjectsByFolderId",
  projects_status: "getProjectStatus",
  folders: "getAllFolders",
  folders_get: "getFolderById",
  foldersByOfficeId_get: "getFoldersByOfficeId",
  foldersByOrganisationId_get: "getFoldersByOrganisationId",
};
