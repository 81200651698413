import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

type Props = {
  onClick: () => void;
};

export const EditButton = ({ onClick }: Props) => {
  return (
    <Button aria-label="edit" variant="link" className="me-1 text-warning" onClick={onClick}>
      <FontAwesomeIcon icon={faEdit} />
    </Button>
  );
};
