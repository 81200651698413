import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { strings } from "../../../config/string";
import { MultiSelect } from "react-multi-select-component";
import { ROLES } from "../../../config/roles";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

type Props = {
  user: User;
  handleSubmit: any;
  register: UseFormRegister<User>;
  setValue: UseFormSetValue<User>;
  errors: any;
  offices: Office[];
  message: string;
};

export const UserForm = ({
  user,
  handleSubmit,
  register,
  setValue,
  errors,
  offices,
  message,
}: Props) => {
  const [selected, setSelected] = useState(
    user.offices
      ? user.offices.map((office) => {
          return { label: office.name, value: office.officeId };
        })
      : []
  );

  const handleSelectOnChange = (data: any) => {
    setSelected(data);
    setValue(
      "offices",
      data.map((office: any) => {
        return { officeId: office.value };
      })
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3" controlId="username">
        <Form.Label column sm="3">
          {strings.formFieldName}
        </Form.Label>
        <Col sm="9">
          <Form.Control
            placeholder={strings.formPlaceholderName}
            readOnly={user.userName ? true : false}
            defaultValue={user.userName}
            className="mb-1"
            {...register("userName", { required: true, maxLength: 80 })}
          />

          {errors.userName ? (
            <span className="text-danger error-message">
              {errors.userName?.message}
            </span>
          ) : (
            <Form.Text className="text-muted">
              {strings.userFormDisclaimer}
            </Form.Text>
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="email">
        <Form.Label column sm="3">
          {strings.formFieldEmail}
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="email"
            placeholder={strings.formPlaceholderEmail}
            readOnly={user.email ? true : false}
            defaultValue={user.email}
            className="mb-1"
            {...register("email", { required: true, maxLength: 80 })}
          />
          <span className="text-danger error-message">
            {errors.email?.message}
          </span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="role">
        <Form.Label column sm="3">
          {strings.formFieldRole}
        </Form.Label>
        <Col sm="9">
          <Form.Select
            className="rounded-0 mb-1"
            defaultValue={user.role}
            key={user.role}
            {...register("role", { required: true })}
          >
            <option value="" label={strings.formPlaceholderRole}></option>
            {ROLES &&
              ROLES.map((item) => (
                <option key={item.value} value={item.value} label={item.label}>
                  {item.value}
                </option>
              ))}
          </Form.Select>
          <span className="text-danger error-message">
            {errors.role?.message}
          </span>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="3">
          {strings.formFieldOffice}
        </Form.Label>

        <Col sm="9">
          <MultiSelect
            labelledBy={strings.formPlaceholderOffice}
            options={
              offices
                ? offices.map((office: Office) => {
                    return { label: office.name, value: office.id };
                  })
                : []
            }
            value={selected}
            onChange={handleSelectOnChange}
          />
          <span className="text-danger error-message">
            {errors.offices?.message}
          </span>
        </Col>
      </Form.Group>
      <Row>
        <Col className="text-danger error-message">{message}</Col>
      </Row>
    </Form>
  );
};
