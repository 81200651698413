import { AddFolderModal } from "../AddFolderModal/AddFolderModal";
import { EditFolderModal } from "../EditFolderModal/EditFolderModal";
import { DeleteFolderModal } from "../DeleteFolderModal/DeleteFolderModal";

type Props = {
  showAdd: boolean;
  showDelete: boolean;
  showEdit: boolean;
  onAddClose: () => void;
  onDeleteClose: () => void;
  onEditClose: () => void;
  folderId?: string;
  officeId?: string;
};

export const FolderCrudModals = ({
  showAdd,
  showDelete,
  showEdit,
  onAddClose,
  onDeleteClose,
  onEditClose,
  folderId,
  officeId,
}: Props) => {
  return (
    <>
      {folderId && (
        <EditFolderModal show={showEdit} folderId={folderId} officeId={officeId} handleClose={onEditClose} />
      )}
      <DeleteFolderModal show={showDelete} folderId={folderId} handleClose={onDeleteClose} />
      <AddFolderModal show={showAdd} officeId={officeId} handleClose={onAddClose} />
    </>
  );
};
