import { EditOfficeModal } from "../EditOfficeModal/EditOfficeModal";
import { DeleteOfficeModal } from "../DeleteOfficeModal/DeleteOfficeModal";
import { AddOfficeModal } from "../AddOfficeModal/AddOfficeModal";

type Props = {
  showAdd: boolean;
  showEdit: boolean;
  showDelete: boolean;
  onEditClose: () => void;
  onDeleteClose: () => void;
  onAddClose: () => void;
  officeId?: string;
};

export const OfficeCrudModals = ({
  showAdd,
  onAddClose,
  showEdit,
  onEditClose,
  showDelete,
  onDeleteClose,
  officeId,
}: Props) => {
  return (
    <>
      <EditOfficeModal show={showEdit} officeId={officeId} handleClose={onEditClose} />
      <DeleteOfficeModal show={showDelete} officeId={officeId} handleClose={onDeleteClose} />
      <AddOfficeModal show={showAdd} handleClose={onAddClose} />
    </>
  );
};
