import * as yup from "yup";
import { strings } from "./string";

export const UserFormValidationSchema = yup
  .object({
    userName: yup
      .string()
      .trim()
      .required(strings.errorMessageName)
      .matches(/^\S*$/, "Blank space not allowed")
      .max(80, strings.errorMessageNameMaxLength),
    email: yup
      .string()
      .email(strings.errorMessageValidEmail)
      .required(strings.errorMessageEmail)
      .max(80, strings.errorMessageNameMaxLength),
    role: yup.string().required(strings.errorMessageRole),
  })
  .required();

export const ProjectFormValidationSchema = yup
  .object({
    name: yup
      .string()
      .required(strings.errorMessageName)
      .max(80, strings.errorMessageNameMaxLength),
    folderId: yup.string().required(strings.errorMessageFolder),
    //projectNumber: yup.number().typeError(strings.errorMessageProjectNumber),
    description: yup
      .string()
      .required(strings.errorMessageDesc)
      .max(300, strings.errorMessageDescMaxLength),
    officeId: yup.string().required(strings.errorMessageOffice),
    projectStatusId: yup.string().required(strings.errorMessageProjectStatus),
  })
  .required();

export const OfficeFormValidationSchema = yup
  .object({
    name: yup
      .string()
      .required(strings.errorMessageName)
      .max(80, strings.errorMessageNameMaxLength),
    description: yup
      .string()
      .required(strings.errorMessageDesc)
      .max(300, strings.errorMessageDescMaxLength),
  })
  .required();

  export const OrganisationFormValidationSchema = yup.object({
    organization: yup.object().shape({
      name: yup
        .string()
        .required(strings.errorMessageName)
        .max(80, strings.errorMessageNameMaxLength),
      description: yup
        .string()
        .required(strings.errorMessageDesc)
        .max(300, strings.errorMessageDescMaxLength),
    }),
  }).required();


export const FolderFormValidationSchema = yup
  .object({
    name: yup
      .string()
      .required(strings.errorMessageName)
      .max(80, strings.errorMessageNameMaxLength),
    officeId: yup.string().required(strings.errorMessageOffice),
  })
  .required();

export const LoginFormValidationSchema = yup
  .object({
    email: yup
      .string()
      .email(strings.errorMessageValidEmail)
      .required(strings.errorMessageEmail)
      .matches(
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        strings.errorMessageValidEmail
      )
      .max(80, strings.errorMessageNameMaxLength),
    password: yup.string().required(strings.errorPasswordIsRequired),
  })
  .required();

export const ResetPasswordFormValidationSchema = yup.object({
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

export const ResetPasswordTokenFormValidationSchema = yup.object({
  email: yup.string().email().required("Enter email"),
});
