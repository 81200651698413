import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom } from "../recoil/auth/atom";
import { useAuthLogin, useRefreshToken } from "../services/authService";
import { strings } from "../config/string";
import jwt_decode from "jwt-decode";
import Roles from "../constants/userRoles";

export const useAuth = () => {
  const setAuth = useSetRecoilState(authAtom);
  const auth = useRecoilValue(authAtom);
  const history = useHistory();
  const { mutateAsync: loginUser } = useAuthLogin();
  const { mutateAsync: refresh } = useRefreshToken();

  const login = async (email, password) => {
    let loginError = "";
    await loginUser({ email: email, password: password }).then(
      (user) => {
        if (user.isAuthenticated) {
          if (user.roles.some((role) => role === Roles.Admin || Roles.User)) {
            onSuccess(user);
          } else {
            loginError = strings.loginRoleError;
          }
        } else {
          loginError = strings.loginError;
        }
      },
      () => {
        loginError = strings.loginError;
      }
    );

    return loginError;
  };

  const onSuccess = (user) => {
    setAuth(user);
    localStorage.setItem("user", JSON.stringify(user));
  };
  const refreshToken = async () => {
    await refresh(auth.id).then(
      (user) => {
        onSuccess(user);
        return true;
      },
      () => {
        logout();
        return false;
      }
    );
    return false;
  };

  const logout = () => {
    localStorage.removeItem("user");
    setAuth(null);
    history.push("/login");
  };
  const isAuthenticated = () => {
    return auth && auth.token && checkToken();
  };
  const checkToken = async () => {
    const decoded = jwt_decode(auth.token);

    if (auth.token.refreshTokenExpiration)
      if (decoded.exp * 1000 < new Date().getTime()) {
        //TODO: check if tokenRefresh expired
        return await refreshToken().then((result) => result);
      } else {
        return false;
      }
  };

  return {
    login,
    logout,
    isAuthenticated,
    refreshToken,
    orgId: auth?.token ? jwt_decode(auth.token).organizationId : null,
  };
};
