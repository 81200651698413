export const strings = {
  //Pages titles
  loginPage: "Sign in",
  userPage: "Manage users",
  projectPage: "Manage projects",
  officePage: "Manage offices",
  organisationPage: "Manage organisations",
  folderPage: "Manage folders",
  indexPage: "Welcome",
  folderDetailPage: "Manage Folder Detail",

  //LoginPage
  login: "Sign in",
  logout: "Sign out",
  loginDisclaimer: "Sign in with your admin account.",
  loginButton: "Sign in",
  loginPassword: "Password",
  loginEmail: "E-mail",

  //UserPage
  addNewUser: "Add new user",
  userFormDisclaimer: "Create a unique username, ie firstname.lastname",
  newUserModalMessage: "Enter user data below",
  newUserModalTitle: "Add new user",
  editUserModalMessage: "Edit user data below",
  editUserModalTitle: "Edit user",
  deleteUserModalTitle: "Delete user",
  deleteUserModalMessage: "Are you sure you want to delete this user? This action can not be un-done.",

  //ProjectPage
  addNewProject: "Add new project",
  newProjectModalTitle: "Add new project",
  newProjectModalMessage: "Input project data below",
  editProjectModalMessage: "Edit project data below",
  editProjectModalTitle: "Edit project",
  deleteProjectModalTitle: "Delete project",
  deleteProjectModalMessage: "Are you sure you want to delete this project? This action can not be un-done.",

  //OfficePage
  addNewOffice: "Add new office",
  newOfficeModalMessage: "Enter office data below",
  newOfficeModalTitle: "Add new office",
  editOfficeModalMessage: "Edit office details below",
  editOfficeModalTitle: "Edit office",
  deleteOfficeModalTitle: "Delete office",
  deleteOfficeModalMessage: "Are you sure you want to delete this office? This action can not be un-done.",
  showFolders: "Show folders",

  //OrganisationPage
  addNewOrganisation: "Add new organisation",
  newOrganisationModalMessage: "Enter organisation data below",
  newOrganisationModalTitle: "Add new organisation",
  editOrganisationModalMessage: "Edit organisation details below",
  editOrganisationModalTitle: "Edit organisation",
  deleteOrganisationModalTitle: "Delete organisation",
  deleteOrganisationModalMessage: "Are you sure you want to delete this organisation? This action can not be un-done.",

  //FolderPage
  addNewFolder: "Add new folder",
  newFolderModalTitle: "Add new folder",
  newFolderModalMessage: "Input folder data below",
  editFolderModalMessage: "Edit folder data below",
  editFolderModalTitle: "Edit folder",
  deleteFolderModalTitle: "Delete folder",
  deleteFolderModalMessage: "Are you sure you want to delete this folder? This action can not be un-done.",
  showProjects: "Show projects",
  back: "Back",

  //Confirmation Modal
  confirmationModalAccept: "Confirm",
  confirmationModalClose: "Cancel",

  //Form labels
  formFieldName: "Name",
  formFieldEmail: "E-mail",
  formFieldOffice: "Office",
  formFieldOganisation: "Organisation",
  formFieldRole: "Role",
  formFieldDesc: "Desc",
  formFieldFolderName: "Folder",
  formFieldProjectNumber: "Project number",
  formFieldProjectStatus: "Project status",

  //Form placeholders
  formPlaceholderName: "Enter name",
  formPlaceholderEmail: "Enter e-mail",
  formPlaceholderOffice: "Select office",
  formPlaceholderOrganisation: "Select organisation",
  formPlaceholderRole: "Select role",
  formPlaceholderDesc: "Enter desc",
  formPlaceholderFolderName: "Select folder",
  formPlaceholderProjectNumber: "Enter project number",
  formPlaceholderProjectStatus: "Select project status",

  //Validation error messages
  loginError: "You have entered an invalid username or password.",
  loginRoleError: "Your role does not match",
  errorMessageName: "Name is required",
  errorMessageEmail: "E-mail is required",
  errorMessageValidEmail: "Valid E-mail is required",
  errorMessageRole: "Role is required",
  errorMessageFolder: "Folder is required",
  errorMessageProjectNumber: "Project number is required",
  errorMessageDesc: "Description is required",
  errorMessageOffice: "Office is required",
  errorMessageProjectStatus: "Project status is required",
  errorMessageNameMaxLength: "Maximum length is 80",
  errorMessageDescMaxLength: "Maximum length is 300",
  errorPasswordIsRequired: "Password is required",
  errorCheckMessage: "is already",

  //MessageToasts
  messageToastHeader: "Information",
  messageToastUpdated: "Successfully updated",
  messageToastDeleted: "Successfully removed",
  messageToastAdded: "Successfully added",
};
