import { useState } from "react";
import { useForm } from "react-hook-form";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { strings } from "../../../config/string";
import { useAddUser } from "../../../services/userService";
import { UserForm } from "../UserForm/UserForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserFormValidationSchema } from "../../../config/FormValidations";
import { useGetAllOffices } from "../../../services/officeService";

type Props = {
  show: boolean;
  handleClose: () => void;
};

export const AddUserModal = ({ show, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(UserFormValidationSchema),
  });

  const { mutateAsync: addUser } = useAddUser();
  const { data: offices } = useGetAllOffices();
  const [responseError, setResoponseError] = useState("");

  const handleConfirmation = (user: User) => {
    addUser(user).then((result) => {
      setResoponseError(result);
      if (!result.includes(strings.errorCheckMessage)) {
        setResoponseError("");
        handleClose();
        reset();
      }
    });
  };

  const handleModalClose = () => {
    setResoponseError("");
    handleClose();
    reset();
  };

  const user = {} as User;

  return (
    <div>
      <ConfirmationModal
        show={show}
        handleClose={handleModalClose}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        message={strings.newUserModalMessage}
        title={strings.newUserModalTitle}
      >
        <UserForm
          setValue={setValue}
          offices={offices || []}
          user={user}
          errors={errors}
          register={register}
          handleSubmit={handleSubmit}
          message={responseError}
        />
      </ConfirmationModal>
    </div>
  );
};
