import { Card } from "react-bootstrap";
import { DeleteButton } from "../../../components/DeleteButton/DeleteButton";
import { DisplayItem } from "../../../components/DisplayItem/DisplayItem";
import { EditButton } from "../../../components/EditButton/EditButton";

type Props = {
  organisation: Organisation;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

export const OrganisationItem = ({
  organisation,
  onClickEdit,
  onClickDelete,
}: Props) => {
  return (
    <DisplayItem>
      <Card.Body>
        <Card.Title>{organisation.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-white opacity-75">
          {organisation.description}
        </Card.Subtitle>
      </Card.Body>
      <Card.Footer className="pt-0">
        <div className="d-flex justify-content-between">
          <EditButton onClick={onClickEdit} />
          <DeleteButton onClick={onClickDelete} />
        </div>
      </Card.Footer>
    </DisplayItem>
  );
};
