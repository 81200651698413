import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { strings } from "../../../config/string";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormValidationSchema } from "../../../config/FormValidations";
import { useHistory } from "react-router-dom";

type Props = {
  onSubmit(data: LoginUser): void;
};

export const LoginForm = ({ onSubmit }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginUser>({
    resolver: yupResolver(LoginFormValidationSchema),
  });
  const history = useHistory();
  return (
    <Form className="w-50" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label>{strings.loginEmail}</Form.Label>
        <Form.Control
          type="email"
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          placeholder="Enter email"
          autoComplete="on"
        />
        {errors.email ? (
          <span className="text-danger">{errors.email?.message}</span>
        ) : (
          <Form.Text className="text-muted">
            {strings.loginDisclaimer}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{strings.loginPassword}</Form.Label>
        <Form.Control
          type="password"
          {...register("password", { required: true })}
          placeholder="Enter password"
          autoComplete="on"
        />
        <span className="text-danger">{errors.password?.message}</span>
      </Form.Group>

      <Button variant="success" type="submit">
        {strings.loginButton}
      </Button>

      <Button
        variant="success"
        style={{
          marginLeft: 5,
        }}
        onClick={() => {
          debugger;
          history.push("/resetpasswordtoken");
        }}
      >
        Reset Password
      </Button>
    </Form>
  );
};
