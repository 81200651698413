import { Button } from "react-bootstrap";

type Props = {
  onClick: () => void;
  text: string;
};

export const AddEntityButton = ({ onClick, text }: Props) => {
  return (
    <Button onClick={onClick} className="ms-auto" variant="warning">
      {text}
    </Button>
  );
};
