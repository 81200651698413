import { ReactNode } from "react";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";

type Props = {
  onUpdate: (data: any) => void;
  data: any;
  handleClose: () => void;
  entityForm: ReactNode;
  show: boolean;
  message: string;
  title: string;
  handleSubmit: any;
  reset: any;
};

export const EditEntityModal = ({
  onUpdate,
  data,
  handleClose,
  entityForm,
  show,
  message,
  title,
  handleSubmit,
  reset,
}: Props) => {
  const handleConfirmation = (inputData: any) => {
    onUpdate(Object.assign(data, inputData));
    handleClose();
    reset();
  };

  const handleModalClose = () => {
    handleClose();
    reset();
  };
  return (
    <div>
      <ConfirmationModal
        show={show}
        handleClose={() => handleModalClose()}
        handleConfirmation={() => handleSubmit(handleConfirmation)()}
        message={message}
        title={title}
      >
        {entityForm}
      </ConfirmationModal>
    </div>
  );
};
