import { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { useRecoilState } from "recoil";
import { strings } from "../../config/string";
import { messageAtom } from "../../recoil/auth/atom";

export const MessageToast = () => {
  const [show, setShow] = useState(false);
  const [text, setText] = useRecoilState(messageAtom);

  useEffect(() => {
    if (text) setShow(true);
  }, [text]);

  return (
    <Toast
      className="position-fixed top-0 start-50 mt-5 translate-middle message-toast"
      onClose={() => {
        setShow(false);
        setText("");
      }}
      show={show}
      delay={3000}
      autohide
    >
      <Toast.Header>
        <strong className="me-auto">{strings.messageToastHeader}</strong>
      </Toast.Header>
      <Toast.Body>{text}</Toast.Body>
    </Toast>
  );
};
