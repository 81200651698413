import { useState } from "react";
import { Row } from "react-bootstrap";
import { useGetAllUsers } from "../../../services/userService";
import { UserItem } from "../UserItem/UserItem";
import { UserCrudModals } from "../UserCrudModals/UserCrudModals";
import { AddEntityButton } from "../../../components/AddEntityButton/AddEntityButton";
import { strings } from "../../../config/string";

export const UsersContainer = () => {
  const { data } = useGetAllUsers();
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();

  return (
    <div>
      <AddEntityButton onClick={() => setShowAdd(true)} text={strings.addNewUser} />
      <UserCrudModals
        showAdd={showAdd}
        showDelete={showDelete}
        showEdit={showEdit}
        userId={selectedUserId}
        onAddClose={() => setShowAdd(false)}
        onDeleteClose={() => setShowDelete(false)}
        onEditClose={() => setShowEdit(false)}
      />
      <Row xs={1} sm={2} lg={3} xl={4} className="mt-3">
        {data &&
          data.map((user: User, index) => (
            <UserItem
              key={`${user.id}_${index}`}
              user={user}
              onClickEdit={() => {
                setSelectedUserId(user.id);
                setShowEdit(true);
              }}
              onClickDelete={() => {
                setSelectedUserId(user.id);
                setShowDelete(true);
              }}
            />
          ))}
      </Row>
    </div>
  );
};
